@import 'animate.css/animate.css';
@import 'balloon-css/balloon.css';
@import 'prismjs/themes/prism.css';
@import '@fortawesome/web-awesome';

/* project settings */
:root {
  --ad-background: var(--violet1);
  --ad-img-border-color: var(--white);
  --ad-img-border-style: solid;
  --ad-img-border-width: var(--border-width-md);
  --ad-lead-weight: bold;
  --ad-link-color: var(--fa-dk-gravy);
  --ad-link-hover-color: var(--fa-dk-violet);
  --ad-min-height: var(--spacing-9xl); /* 160px */
  --blockquote-quote-border-color: var(--black-transparent2);
  --ad-tag-top: var(--top-tag-top);
  --carbon-ad-min-height: calc(var(--spacing-base) * 7); /* ~size of carbon ad's image */
  --code-font-size: 0.9em;
  --monetization-ad-min-height: calc(var(--spacing-base) * 5); /* size of single line in monetization ad */
  --grid-outer-gutter-width: 1.5rem;
  --icon-details-preview-font-size: 10rem; /* 160px */
  --icon-details-preview-line-height: 0;
  --icon-details-preview-arrows-top: 23em; /* distance arrows are from the top of the modal. This is an absolute number so the buttons don't move if the height changes on the modal. */
  --icon-details-preview-min-width: var(--spacing-12xl); /* 320px */
  --icon-details-preview-min-height: var(--spacing-12xl); /* 256px */
  --icon-details-unicode-glyphs-label-min-width: var(--spacing-5xl);
  --icon-listing-roomy-font-size: var(--size-sm); /* 14px */
  --icon-listing-roomy-icon-size: calc(var(--size-base) * 2.25); /* 36px */
  --icon-listing-roomy-gap-x: var(--spacing-xl); /* 24px */
  --icon-listing-roomy-gap-y: var(--spacing-md); /* 16px */
  --icon-listing-roomy-size: calc(var(--spacing-base) * 10); /* ~166px - 184px */
  --icon-listing-compact-font-size: var(--size-xs); /* 12px */
  --icon-listing-compact-icon-size: var(--size-2xl); /* 32px */
  --icon-listing-compact-size: calc(var(--spacing-base) * 7.5); /* 105px, 120px */
  --icon-listing-cheatsheet-font-size: calc(var(--size-base) * 0.6875); /* 11px */
  --icon-listing-cheatsheet-icon-size: var(--size-lg); /* 20px */
  --icon-listing-cheatsheet-size: calc(var(--spacing-base) * 10.5); /* 147px, 168px */
  --icon-code-snippet-padding-horizontal: var(--spacing-lg);
  --element-sm-loading-min-height: calc(var(--spacing-base) * 20); /*  ~320px  */
  --element-md-loading-min-height: calc(var(--spacing-base) * 30); /*  ~480px  */
  --icon-details-input-color-min-width: var(--spacing-4xl);
  --nudge-background-color: var(--fa-dk-blue); /* WA-add nudge pattern */
  --nudge-color: var(--white);
  --nudge-arrow-size: 1rem;
  --nudge-pointer-position-left: unset;
  --nudge-pointer-position-right: unset;
  --nudge-pointer-orientation-top: unset;
  --nudge-pointer-orientation-bottom: unset;
  --nudge-pointer-display: block;
  --aspect-width: unset; /* WA-add aspect ratios */
  --aspect-height: unset; /* WA-add aspect ratios */
  --button-disabled-color: var(--button-color); /* WA-add disabled buttons */
  --sticky-element-offset: var(--spacing-xl);
  --pack-listing-transition-curve: 0.68, -0.55, 0.27, 1.55; /* icon pack listing filtering/sorting */

  /* WA-add: set a background for disabled select inputs  */
  --icon-ban: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='rgb(194, 199, 209)' d='M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z'%3E%3C/path%3E%3C/svg%3E");
  /* icon packs */
  --icon-pack-notdog-color-off-white: rgb(255, 248, 249);
}

/* WA-add: aspect-ratio utilities */
.aspect-ratio {
  height: 0;
  position: relative;
  padding-bottom: calc(var(--aspect-ratio-height) / var(--aspect-ratio-width) * 100%);
}

.aspect-ratio-object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect-ratio-16x9 {
  --aspect-ratio-height: 9;
  --aspect-ratio-width: 16;
}

.aspect-ratio-9x16 {
  --aspect-ratio-width: 9;
  --aspect-ratio-height: 16;
}

/* WA-add disabled buttons */
:is(button, .button, .btn, [type='button'], [type='reset'], [type='submit']):disabled,
.disabled:is(button, .button, .btn, [type='button'], [type='reset'], [type='submit']) {
  color: var(--button-disabled-color);
}

/* WA-add button card */
button.card:focus,
.button.card:focus {
  outline: var(--focus-outline);
}

/* WA: extending themes */
:is(.pixel) {
  --theme-color: #a9e34b;
  --theme-color-dk: #66a80f;
  --button-background: var(--theme-color);
  --button-hover-background: var(--theme-color-dk);
  --button-active-color: var(--button-background);
  --button-disabled-background: var(--button-background);
  --message-background: var(--white);
  --message-border-color: var(--theme-color-dk);
  --message-with-icon-decorative-color: var(--message-border-color);
  --with-close-close-color: var(--message-border-color);
  --card-background: var(--white);
  --card-color: var(--theme-color-dk);
  --card-link-background: var(--card-background);
  --card-link-color: var(--card-color);
  --card-link-hover-box-shadow-color: var(--card-link-hover-border-color);
  --card-link-hover-background: var(--white);
  --card-link-hover-color: var(--card-color);
  --card-link-hover-border-color: var(--card-color);
  --card-header-background: var(--theme-color);
  --card-header-color: var(--fa-navy);
  --card-header-link-hover-background: var(--card-link-hover-color);
  --card-header-link-hover-color: var(--card-link-hover-background);
}

/* dev dashboard - TODO: remove from prod css */
.dev-dashboard-is-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.dev-dashboard-status-bar .off {
  opacity: 0.3;
}

.dev-dashboard-status-bar path:hover,
.dev-dashboard-status-bar .active {
  opacity: 1;
}

.dev-dashboard-subscription-status .gray8 {
  opacity: 1;
}

.dev-dashboard-subscription-status .gray3 {
  opacity: 0.3;
}

.dev-dashboard-subscription-status-bar {
  cursor: pointer;
}

.dev-dashboard-subscription-status-bar.teal {
  color: var(--fa-teal);
}

.dev-dashboard-subscription-status-bar.teal:hover,
.dev-dashboard-subscription-status-bar.teal:focus,
.dev-dashboard-subscription-status-bar.teal.active {
  color: var(--fa-dk-teal);
}

.dev-dashboard-subscription-status-bar.warning {
  color: var(--fa-yellow);
}

.dev-dashboard-subscription-status-bar.warning:hover,
.dev-dashboard-subscription-status-bar.warning:focus,
.dev-dashboard-subscription-status-bar.warning.active {
  color: var(--fa-dk-yellow);
}

.dev-dashboard-subscription-status-bar.danger {
  color: var(--fa-red);
}

.dev-dashboard-subscription-status-bar.danger:hover,
.dev-dashboard-subscription-status-bar.danger:focus,
.dev-dashboard-subscription-status-bar.danger.active {
  color: var(--fa-dk-red);
}

.dev-dashboard-subscription-status-bar.gray {
  color: var(--fa-gravy);
}

.dev-dashboard-subscription-status-bar.gray:hover,
.dev-dashboard-subscription-status-bar.gray:focus,
.dev-dashboard-subscription-status-bar.gray.active {
  color: var(--fa-dk-gravy);
}

/* make a semantic button look and act like one of our WA links - WA fix */
button.link.inline,
[type='button'].link.inline {
  --button-border-radius: 0;
  --button-margin-bottom: 0;
  --button-padding: 0;

  text-decoration-color: currentColor;
  text-decoration-line: var(--link-decoration-line);
  text-decoration-style: var(--link-decoration-style);
  text-decoration-thickness: var(--link-decoration-thickness);
}

button.link.inline:active,
[type='button'].link.inline:active {
  top: 0;
}

button.link.inline:focus,
[type='button'].link.inline:focus {
  outline: var(--focus-outline);
}

/* stop sizing collisions between SVG + WebFont versions of FA in our app */
.svg-inline--fa.fa-stack-2x {
  font-size: inherit; /* resets font-size: 2em that cascades in from FA's Web Fonts CSS and applies to SVG-based icons */
}

/* hiding reCAPTCHA badge (see https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed for more info) */
.grecaptcha-badge {
  visibility: hidden;
}

/* fixing letter spacing for password fields that use Cera Rounded Pro (which displays characters in a very narrow way) */
[type='password'] {
  letter-spacing: var(--letter-spacing-3xl);
}

/* disabling styles for links - matching buttons */
a:disabled,
a.disabled {
  --link-color: var(--link-disabled-color);
  --link-hover-color: var(--link-disabled-color);
  opacity: var(--link-disabled-opacity);
  pointer-events: none;
}

.select-file-upload label {
  --border-style: dashed;

  color: var(--fa-dk-blue);
  cursor: pointer;
  margin: 0;
  padding: var(--spacing-xl);
  border-color: var(--fa-gravy);
  border-width: var(--border-width-sm);
  border-style: var(--border-style);
}

.select-file-upload label:hover,
.select-file-upload label:focus {
  transition-property: all;
  transition-duration: var(--timing-xfast);
  transition-timing-function: ease-in-out;
  border-color: var(--fa-dk-blue);
  color: var(--fa-blue);
}

/* swap icons on hover/focus */
.swap-icons .initial-icon,
.swap-icons .hover-icon {
  position: relative;
  transition-property: opacity;
  transition-duration: var(--timing-xfast);
  transition-timing-function: ease-in-out;
}

.swap-icons .initial-icon {
  opacity: 1;
}

.swap-icons .hover-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swap-icons:hover .initial-icon {
  opacity: 0;
}

.swap-icons:hover .hover-icon {
  opacity: 1;
}

/* hover-show: a generic way to have things show on hover */
.click-show + .click-shown {
  display: none;
}

.click-show:active + .click-shown,
.click-show.active + .click-shown {
  display: block;
}

/* nudges */
.nudge {
  background-color: var(--nudge-background-color);
  color: var(--nudge-color);
  box-shadow: 2px 2px 4px var(--fa-dk-navy-transparent2);
}

.nudge:after {
  --nudge-pointer-display: none;

  display: var(--nudge-pointer-display);
  left: var(--nudge-pointer-orientation-left);
  right: var(--nudge-pointer-orientation-right);
  top: var(--nudge-pointer-orientation-top);
  bottom: var(--nudge-pointer-orientation-bottom);
  transform: rotate(45deg);
  width: var(--nudge-arrow-size);
  height: var(--nudge-arrow-size);
  background-color: var(--nudge-background-color);
  pointer-events: none;
  content: '';
  position: absolute;
  border-radius: 3px;
  /* box-shadow: 2px 2px 4px var(--fa-dk-navy-transparent2); */
}

.nudge a {
  color: inherit;
  font-weight: inherit;
}

.nudge.with-icon .decorative {
  color: var(--nudge-color);
}

.nudge.with-close .close {
  transition: color ease-in-out var(--timing-xfast);
  color: var(--fa-blue);
}

.nudge.with-close .close:hover {
  color: var(--white);
}

/* modals */

/* specific modal widths */
.modal .icon-detail-modal-content,
.modal .pro-icon-modal-content,
.modal .pro-feature-modal-content,
.modal .about-frameworks-modal-content,
.modal .kits-and-domains-modal-content,
.modal .upsell-modal-content {
  --modal-max-width: 80vw;
}

/* web awesome modal positioning */
.web-awesome-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: none;
}

.modal .web-awesome-modal-content {
  width: 360px;
  --modal-max-width: 360px;
}

.wa-kickstarter-video {
  width: 360px;
  height: 202px;
  margin: 0 auto;
  display: block;
}

/* container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* setting flex layout for app elements */
.app-header,
.app-sweatband,
.app-sock,
.app-footer {
  flex-shrink: 0;
}

.app-content {
  flex: 1 0 auto;
}

/* style admin bar links if on current site area or URL */
.app-admin-bar a.active {
  color: var(--white);
}

/* header */
.app-header-menu-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.app-header-links .wrap-links {
  margin-right: calc(var(--spacing-md) * -1);
}

.app-header-menu .wrap-links {
  margin-right: calc(var(--spacing-md) * -1);
  margin-left: calc(var(--spacing-md) * -1);
}

.app-header-links {
  flex-grow: 1;
}

.app-header .link-search {
  display: inline-block;
  border: 2px solid var(--fa-lt-gravy);
  border-radius: var(--border-radius-circle);
  line-height: 1;
}

.app-header .link-search:hover {
  background-color: var(--fa-blue);
  color: var(--white);
  border-color: var(--fa-blue);
}

.app-header-account {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.app-header .icons-search .with-icon-before .icon-decorative {
  top: 13px !important; /* TODO: re-work web awesome input padding scales and remove this magic number */
}

/* style header nav links if on current site area or URL */
.app-header-links a.active,
.app-header-account a.active {
  color: var(--link-active-color, initial);
}

/* overriding heavy .button.flat active background set as an override here - WA fix  */
.app-header .button.flat,
.app-header button.flat {
  --button-active-background: transparent;
}

/* bandana */
.bandana-v7-teaser img {
  max-width: 100%;
}

.bandana-v7-teaser .pancakes {
  width: 250px;
  bottom: 55%;
  right: 65%;
}

.bandana-v7-teaser .coffee {
  width: 150px;
  top: 55%;
  left: 80%;
}

.bandana-v7-teaser .menu {
  width: 375px;
  top: 85%;
  right: 40%;
}

/* footer */
.app-footer .wrap-links {
  margin-right: calc(var(--spacing-md) * -1);
  margin-left: calc(var(--spacing-md) * -1);
}

.app-footer-heart-flutter:hover,
.app-footer-heart-flutter:focus,
.app-footer-heart-flutter:active {
  color: var(--fa-red);
  animation: fa-beat 1s infinite ease-in-out;
}

/*  logo flag */
.logo-flag {
  transform: translateY(-0.15em);
  color: var(--logo-color);
  transition-property: color;
  transition-duration: var(--timing-2xfast);
  transition-timing-function: ease-in-out;
}

.logo-flag:hover {
  color: var(--logo-hover-color);
}

/* ads */
.wrap-ad {
  background: var(--ad-background);
}

.wrap-ad a {
  text-decoration: none;
  color: var(--ad-link-color);
}

.wrap-ad a:hover,
.wrap-ad a:focus {
  color: var(--ad-link-hover-color);
}

/* carbon ad */
/* stopping carbon ad from jumping around when UI is refreshed */
.carbon-ad {
  min-height: var(--carbon-ad-min-height);
}

.carbon-ad .carbon-wrap {
  display: inline-block;
  margin-bottom: 0;
}

.carbon-ad .carbon-img,
.carbon-ad .carbon-img img,
.carbon-ad .carbon-text,
.carbon-ad .carbon-powered-by {
  display: inline-block;
}

.carbon-ad .carbon-img img {
  vertical-align: middle;
  border-width: var(--ad-img-border-width);
  border-style: var(--ad-img-border-style);
  border-color: var(--ad-img-border-color);
}

.carbon-ad .carbon-text {
  margin: var(--spacing-2xs) var(--spacing-lg);
  line-height: var(--line-height-sm);
  color: var(--ad-lead-color);
}

.carbon-ad .carbon-poweredby {
  white-space: nowrap;
}

.icon-discovery .wrap-ad.with-close .close {
  margin-top: 0 !important; /* overriding Web Awesome's form-based margin rules for buttons */
}

.icon-landing .carbon-ad .carbon-wrap {
  display: flex;
  text-align: left;
  align-items: flex-start;
}

.icon-landing .carbon-ad .carbon-poweredby {
  position: absolute;
  bottom: 0;
  right: 0;
}

.icon-landing .carbon-ad .carbon-wrap {
  align-items: center;
}

.category-landing .category-header .wrap-carbon-ad {
  --card-border-width: 1px;
  --card-border-color: var(--fa-dk-violet);
}

/* icon packs */
.icon-pack-card-brands {
  --card-link-hover-box-shadow-color: var(--fa-dk-navy);
  --card-link-hover-border-color: var(--fa-dk-navy);
  --card-header-background: var(--fa-navy);
}

.icon-pack-card-classic {
  --card-link-hover-box-shadow-color: var(--fa-dk-yellow);
  --card-link-hover-border-color: var(--fa-dk-yellow);
  --card-header-background: var(--fa-yellow);
}

.icon-pack-card-duotone {
  --card-link-hover-box-shadow-color: var(--fa-dk-blue);
  --card-link-hover-border-color: var(--fa-dk-blue);
  --card-header-background: var(--fa-blue);
}

.icon-pack-card-sharp {
  --card-link-hover-box-shadow-color: var(--fa-dk-purple);
  --card-link-hover-border-color: var(--fa-dk-purple);
  --card-header-background: var(--fa-purple);
}

.icon-pack-card-sharp-duotone {
  --card-link-hover-box-shadow-color: var(--fa-dk-violet);
  --card-link-hover-border-color: var(--fa-dk-violet);
  --card-header-background: var(--fa-violet);
}

.icon-pack-card-chisel {
  --card-link-hover-box-shadow-color: var(--fa-dk-teal);
  --card-link-hover-border-color: var(--fa-dk-teal);
  --card-header-background: var(--fa-teal);
}

.icon-pack-card-etch {
  --card-link-hover-box-shadow-color: var(--fa-dk-blue);
  --card-link-hover-border-color: var(--fa-dk-blue);
  --card-header-background: var(--fa-blue);
}

.icon-pack-card-jelly {
  --card-link-hover-box-shadow-color: var(--fa-dk-violet);
  --card-link-hover-border-color: var(--fa-dk-violet);
  --card-header-background: var(--fa-violet);
}

.icon-pack-card-notdog {
  --card-link-hover-box-shadow-color: var(--fa-dk-yellow);
  --card-link-hover-border-color: var(--fa-dk-yellow);
  --card-header-background: var(--fa-navy);
}

.icon-pack-card-slab {
  --card-link-hover-box-shadow-color: var(--fa-dk-red);
  --card-link-hover-border-color: var(--fa-dk-red);
  --card-header-background: var(--fa-red);
}

.icon-pack-card-thumbprint {
  --card-link-hover-box-shadow-color: var(--fa-gravy);
  --card-link-hover-border-color: var(--fa-gravy);
  --card-header-background: var(--fa-lt-gravy);
}

.icon-pack-card-whiteboard {
  --card-link-hover-box-shadow-color: var(--fa-blue);
  --card-link-hover-border-color: var(--fa-blue );
  --card-header-background: var(--blue1);
}

.icon-pack-landing .icon-pack-metadata {
  flex-grow: 1;
  gap: var(--spacing-sm);
}

.icon-pack-landing .icon-pack-metadata-item {
  flex-grow: 1;
}

.icon-pack-landing .pack-hero {
  --icon-pack-hero-min-height: 20rem; /* ~320px */
}

.icon-pack-landing .pack-mockups {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}

.icon-pack-landing .pack-mockup {
  line-height: 0;
  overflow: hidden;
}

.icon-pack-landing .pack-mockup-image {
  height: auto;
  width: 100%;
  transition: all var(--timing-xfast) ease-in-out;
}

.icon-pack-landing .pack-mockup-image:hover,
.icon-pack-landing .pack-mockup-image:focus {
  transform: scale(1.05);
}

/* TODO: maybe address this in the Icon.vue component instead */
.icon-pack-landing .icon-listing .wrap-icon .tag {
  display: none;
}

.icon-pack-landing .icon-design {
  --size: 28;
  --padding: 2;

  --canvas-width: 100%;
  --grid-line-thickness: 0.5; /* as a percentage of width */
  --grid-line-color: var(--fa-gravy);
  --reticle-line-thickness: 0.25;
  --reticle-line-color: var(--fa-navy);
  --reticle-line-width: 1;

  --_canvas-size: var(--canvas-size, calc(var(--size) + 2 * var(--padding)));
  --_gradient-stops: var(--grid-line-color) 0 calc(var(--grid-line-thickness) * 1%),
    transparent 0 calc((100% - var(--grid-line-thickness) * 1%) / var(--_canvas-size));

  width: var(--canvas-width);
  background: repeating-linear-gradient(var(--_gradient-stops)), repeating-linear-gradient(90deg, var(--_gradient-stops));

  /* Add 0.5 of the grid lines in each direction via padding */
  padding: calc(var(--grid-line-thickness) / 100 * var(--canvas-width) / 2);

  overflow: visible;

  transition: --grid-line-color var(--timing-slow) ease-in-out;
}

.icon-pack-landing .icon-design.is-highlighting-grid {
  --reticle-line-color: var(--icon-design-specimen-primary-color);
}

.icon-pack-landing .icon-design.is-highlighting-canvas {
  --grid-line-color: var(--icon-design-specimen-secondary-color);
}

.icon-pack-landing .icon-design.is-focused {
  --grid-line-color: var(--grid-hover-line-color, var(--link-hover-color));
}

.icon-pack-landing .reticle {
  fill: none;
  stroke: var(--reticle-line-color);
  stroke-width: var(--reticle-line-thickness);
  stroke-linejoin: round;
  stroke-linecap: round;
  --_size: calc(var(--size) * 1px);
  width: var(--_size);
  height: var(--_size);
  x: calc(var(--padding) * 1px);
  y: calc(var(--padding) * 1px);
  stroke-dasharray: calc(2px * var(--reticle-line-width)) calc(1px * (var(--size) - 2 * var(--reticle-line-width)));
  stroke-dashoffset: var(--reticle-line-width);
}

.icon-pack-landing .playground {
  transition: all var(--timing-xfast) ease-in-out;
}

.icon-pack-landing .playground-icon-container:hover,
.icon-pack-landing .playground-icon-container:focus {
  --fa-secondary-color: var(--theme-action-primary-dk);
}

.icon-pack-landing .playground-icon {
  transition: font-size var(--timing-xfast) ease-in-out;
}

.icon-pack-landing .playground-icon:after,
.icon-pack-landing .playground-icon:before {
  transition: color var(--timing-xfast) ease-in-out;
}

/* custom range slider */
.icon-pack-landing .playground-control-size {
  transition: color, background var(--timing-2xfast) ease-in;
}

.icon-pack-landing .playground-control-size:hover,
.icon-pack-landing .playground-control-size:focus {
  --border-color: var(--theme-button-hover-border-color);
  background: var(--theme-button-hover-background);
}

.icon-pack-landing #playground-control-size-input {
  transition: background-color var(--timing-xfast) ease-in-out;
  accent-color: var(--input-range-accent-color, var(--link-color));
}

/* custom select icon override */
/* TODO: update this fill - WA fix */
.icon-pack-landing .playground.inverted .select-custom-animation {
  --select-background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='caret-down' class='svg-inline--fa fa-caret-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='rgb(255,255,255)' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'%3E%3C/path%3E%3C/svg%3E");
}
.icon-pack-landing .playground .select-custom-animation {
  transition: color, background var(--timing-2xfast) ease-in;
}

.icon-pack-landing .playground .select-custom-animation:hover,
.icon-pack-landing .playground .select-custom-animation:focus {
  --input-background: var(--theme-button-hover-background);
  --input-border-color: var(--theme-button-hover-border-color);
}

/* monetization ad */
/* stopping monetization ad from jumping around when UI is refreshed */
.monetization-ad {
  min-height: var(--monetization-ad-min-height);
}

.monetization-ad ._default_ {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.monetization-ad .default-image {
  margin-right: var(--spacing-lg);
  & img {
    max-width: none;
  }
}

.monetization-ad .default-ad {
  border: var(--tag-border-width) var(--tag-border-style) var(--tag-border-color);
  border-radius: var(--tag-border-radius);
  background: var(--fa-dk-violet);
  padding: var(--tag-padding);
  vertical-align: var(--tag-vertical-align);
  text-transform: var(--tag-text-transform);
  line-height: 1;
  letter-spacing: var(--tag-letter-spacing);
  white-space: nowrap;
  color: var(--white);
  font-size: var(--tag-font-size);
  font-weight: var(--tag-font-weight);
  position: absolute;
  top: var(--ad-tag-top);
  z-index: 1;
}

.monetization-ad .default-title {
  font-weight: var(--ad-lead-weight);
  display: block;
  margin-bottom: var(--spacing-3xs);
}

.modal .icon-detail .wrap-monetization-ad {
  padding: var(--spacing-lg);
}

/* system */

/* 404 + Not Found */
.hover-animate-hand-shake:hover,
.hover-animate-hand-shake:focus {
  animation: fa-shake 2s linear infinite 0s;
}

.hover-animate-bounce-block:hover,
.hover-animate-bounce-block:focus {
  --fa-bounce-start-scale-x: 1;
  --fa-bounce-start-scale-y: 1;
  --fa-bounce-jump-scale-x: 1;
  --fa-bounce-jump-scale-y: 1;
  --fa-bounce-land-scale-x: 1;
  --fa-bounce-land-scale-y: 1;

  animation: fa-bounce 1s cubic-bezier(0.28, 0.84, 0.42, 1) infinite 0s;
}

/* 503 maintenance + transporter joke */
@keyframes beamMeUp {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* container for all states */
.transporter-beaming-up {
  --animation-duration-entire: 5s;
  --animation-duration-step: 0.5s;
  --animation-delay: 0s;

  position: relative;
}

/* make physical space for all states to animation into */
.transporter-beaming-up-stint {
  z-index: var(--depth-1);
}

/* individual states */
.transporter-beaming-up-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 0;
  animation-name: beamMeUp;
  animation-duration: var(--animation-duration-entire);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: var(--animation-delay);
  z-index: var(--depth-2);
}

/* timing adjustments for each state */
.transporter-beaming-up-state:nth-child(2) {
  --animation-delay: calc(var(--animation-duration-step) * 1);
}

.transporter-beaming-up-state:nth-child(3) {
  --animation-delay: calc(var(--animation-duration-step) * 2);
}

.transporter-beaming-up-state:nth-child(4) {
  --animation-delay: calc(var(--animation-duration-step) * 3);
}

.transporter-beaming-up-state:nth-child(5) {
  --animation-delay: calc(var(--animation-duration-step) * 4);
}

.transporter-beaming-up-state:nth-child(6) {
  --animation-delay: calc(var(--animation-duration-step) * 5);
}

.transporter-beaming-up-state:nth-child(7) {
  --animation-delay: calc(var(--animation-duration-step) * 6);
}

.transporter-beaming-up-state:nth-child(8) {
  --animation-delay: calc(var(--animation-duration-step) * 7);
}

.transporter-beaming-up-state:nth-child(9) {
  --animation-delay: calc(var(--animation-duration-step) * 8);
}

.transporter-beaming-up-state:nth-child(10) {
  --animation-delay: calc(var(--animation-duration-step) * 9);
}

/* giving things a little extra time */
.transporter-beaming-up-end {
  opacity: 0 !important;
}

/* v7 home */
.home.home-nav.sticky-element {
  transition-duration: var(--timing-fast);
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
}

.fa7-pro-features-card-grid {
  display: grid;
  grid-gap: var(--spacing-xl);
  grid-template-columns: repeat(8, 1fr);
}

.fa7-pro-features-card-grid .card-1 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 4;
}

.fa7-pro-features-card-grid .card-2 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 4;
  grid-row-end: 5;
}

.fa7-pro-features-card-grid .card-3 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 6;
}

.fa7-pro-features-card-grid .card-4 {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 6;
}

/* plans */
.wrap-compare-tables .plan-compare-table:first-child {
  z-index: var(--depth-1);
  position: sticky;
  top: 0;
}

.plan-compare-table dd:first-child {
  border-top-left-radius: var(--border-radius-md);
  border-bottom-left-radius: var(--border-radius-md);
}

.plan-compare-table dd:last-child {
  border-top-right-radius: var(--border-radius-md);
  border-bottom-right-radius: var(--border-radius-md);
}

/* Plans slider switch */
.plans-switch {
  --switch-padding: var(--spacing-xs);
  --switch-easing: cubic-bezier(0.47, 1.64, 0.41, 0.8);
  color: var(--fa-md-gravy);
  width: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: var(--border-radius-pill);
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  isolation: isolate;
  padding: var(--spacing-sm);
}

/* hide radio button */
.plans-switch input[type='radio'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* unchecked state */
.plans-switch > label {
  cursor: pointer;
  display: grid;
  place-content: center;
  transition: opacity var(--timing-xfast) ease-in-out var(--timing-2xfast);
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: transparent;
}

/* checked state */
.plans-switch > label:has(input:checked) {
  background: var(--fa-navy);
  color: var(--white);
  border-radius: inherit;
  transition: background var(--timing-xfast) ease-in-out var(--timing-2xfast);

  .fa-sparkles {
    color: var(--fa-teal);
  }

  .fa-globe {
    color: var(--fa-yellow);
  }
}

/* set up the slider in the background */
.plans-switch::before,
.plans-switch::after {
  content: '';
  position: absolute;
  border-radius: inherit;
  transition: inset var(--timing-2xfast) ease-in-out;
}

/* switch slider */
.plans-switch::before {
  background-color: var(--fa-navy);
  inset: var(--switch-padding) 50% var(--switch-padding) var(--switch-padding);
  transition: inset var(--timing-fast) var(--switch-easing), background-color var(--timing-fast) ease-in-out;
  z-index: -1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.3);
}

/* switch wrapper */
.plans-switch::after {
  background-color: var(--fa-gravy-transparent5);
  inset: 0;
  z-index: -2;
}

/* switch hover & focus */
.plans-switch label:has(input:not(:checked)) {
  opacity: 0.75;
}

.plans-switch label:has(input:checked):hover {
  opacity: 1;
  transition-delay: 0ms;
  transition-duration: var(--timing-2xfast);
}

.plans-switch label:has(input:not(:checked)):hover {
  opacity: 1;
  transition-delay: 0ms;
  transition-duration: var(--timing-2xfast);
}

/* checked states - move the slider */
.plans-switch:has(input.plus:checked)::before {
  inset: var(--switch-padding) 50% var(--switch-padding) var(--switch-padding);
}
.plans-switch:has(input.standard:checked)::before {
  inset: var(--switch-padding) var(--switch-padding) var(--switch-padding) 50%;
}

/* Plan Card: color classes for states */
.plan-card .text-gray {
  color: var(--gray-500);
}

.plan-card .text-warning {
  color: var(--fa-yellow);
}

.plan-card .text-danger {
  color: var(--fa-red);
}

/* purchase views + flows */
.purchase-plan,
.purchase-commission {
  order: -1;
  flex-shrink: 0;
}

.purchase-form {
  flex-grow: 1;
}

/* overriding Web Awesome's display rule for labels next to input elems */
.payment-method-checkout-label {
  display: flex !important;
}

/* Stripe Elements field styling - reiterating all text input WA rules */
.StripeElement {
  background: var(--input-background);
  border-color: var(--input-border-color);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius);
  border-style: var(--input-border-style);
  box-shadow: var(--input-box-shadow);
  font-size: var(--input-font-size);
  padding: var(--input-padding);
}

.StripeElement--invalid {
  --input-border-color: var(--fa-red);
}

/* icons search */

/* Web Awesome depends on a <form> element for styling, we can't have that in our icon search, so this recreates those styles with a non <form>-based selector */
/* TODO: revise Web Awesome form styling to be more flexible */
.icons-search .with-icon-before {
  position: relative;
}

.icons-search .with-icon-before input {
  padding-left: calc(
    (var(--input-padding-horizontal) - var(--input-border-width)) * 1.6 + var(--input-with-icon-scale) * 1em
  ); /* icon width plus space on either side */
}

.icons-search .with-icon-before i[class*='fa-'],
.icons-search .with-icon-before .svg-inline--fa {
  display: inline-block;
  position: absolute;
  top: calc((var(--input-min-height) / var(--input-with-icon-scale) - 1em) / 2);
  left: calc(var(--input-padding-horizontal) / var(--input-with-icon-scale));
  color: var(--input-with-icon-color);
  font-size: calc(var(--input-with-icon-scale) * 1em);
  pointer-events: none;
}

.icons-search .with-icon-after {
  position: relative;
}

.icons-search .with-icon-after i[class*='fa-'],
.icons-search .with-icon-after .svg-inline--fa {
  display: inline-block;
  position: absolute;
  top: calc((var(--input-min-height) / var(--input-with-icon-scale) - 1em) / 2); /* Automatically scales as icon size scales */
  right: calc(var(--input-padding-horizontal) / var(--input-with-icon-scale));
  color: var(--input-with-icon-color);
  font-size: calc(var(--input-with-icon-scale) * 1em);
  pointer-events: none;
}

.icons-search .with-icon-after input {
  padding-right: calc(
    (var(--input-padding-horizontal) - var(--input-border-width)) * 1.6 + var(--input-with-icon-scale) * 1em
  ); /* icon width plus space on either side */
}

.icons-search-suggest {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc((var(--input-padding-horizontal) - var(--input-border-width)) * 1.6 + var(--input-with-icon-scale) * 1em);
  width: 88%;
}

.icons-search-decorative {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.icons-search-clear,
.icons-search-go,
.icons-search-clear-plus-version,
.icons-search-go-plus-version {
  --button-color: var(--fa-gravy);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--spacing-sm);
}

.icons-search-clear-plus-version,
.icons-search-go-plus-version {
  right: var(--spacing-8xl);
}

.icon-search-powered-by {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--spacing-2xl);
}

/* icon search: drop downs */
.icons-sort-selector .ais-SortBy-select,
.icons-version-selector .select-custom-select {
  --input-font-weight: var(--font-weight-normal);
  --input-padding: var(--spacing-sm) var(--input-padding-horizontal);
}

.icons-sort-selector .ais-SortBy-select {
  --input-min-height: auto;

  width: 100%;
  margin-bottom: 0;
  padding-right: var(--spacing-4xl) !important;
}

/* icons sort */
.icons-sort-select {
  background-image: none;
}

.icons-controls-display {
  order: -1;
}

.icons-sort-affordance {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.icons-style-choice:hover,
.icons-style-choice:focus {
  color: var(--fa-dk-blue);
}

.icon-style-choice {
  margin: 0 !important;
}

.with-icon:checked ~ .icons-style-choice {
  color: var(--fa-navy);
  font-weight: var(--font-weight-bold) !important;
}

/* style selection menu */
.icons-style-menu-listing {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: var(--spacing-sm);
}

/* faceted browsing */
.icons-facet-affordance {
  --icon-facet-affordance-color: inherit;

  color: var(--icon-facet-affordance-color);
}

.icons-facet-count {
  --icon-facet-count-color: inherit;

  color: var(--icon-facet-count-color);
}

/* input-based facets */
.icons-facet-input {
  --icon-facet-affordance-color: var(--fa-md-gravy);
  --icon-facet-count-color: var(--fa-md-gravy);

  border: var(--border-width) var(--border-style) transparent;
  border-radius: var(--border-radius);
  transition-property: background-color, border-color, color;
  transition-duration: var(--timing-2xfast);
  transition-timing-function: ease-in;
}

.wrap-icons-facet-input .icons-facet:hover,
.wrap-icons-facet-input .icons-facet:active {
  border-color: var(--border-color);
}

.wrap-icons-facet-input .with-icon:checked ~ .icons-facet,
.wrap-icons-facet-input .with-icon:checked:hover ~ .icons-facet,
.wrap-icons-facet-input .with-icon:checked:active ~ .icons-facet {
  border-color: var(--fa-dk-blue);
  background: var(--fa-dk-blue);
  color: var(--white);
}

.wrap-icons-facet-input .with-icon:checked ~ .icons-facet .icons-facet-affordance {
  --icon-facet-affordance-color: var(--white);
}

.wrap-icons-facet-input .with-icon:checked ~ .icons-facet .icons-facet-count {
  --icon-facet-count-color: var(--white);
}

/* button-based facets */
.icons-facet-button {
  --button-border-color: var(--fa-lt-gravy) !important;
  --button-background: transparent !important;
  --button-hover-background: transparent !important;
  --button-margin-bottom: 0 !important;
  --button-border-width: var(--border-width-sm) !important;
  --button-hover-border-width: var(--border-width-sm) !important;
  --button-hover-border-color: var(--fa-dk-blue) !important;
  --button-box-shadow-width: 0 !important;
  --button-box-shadow: none !important;
  --icon-facet-affordance-color: var(--fa-md-gravy);
  --icon-facet-count-color: var(--fa-md-gravy);
}

.icons-facet-button:hover {
  z-index: var(--depth-4);
}

.icons-facet-button:active {
  z-index: var(--depth-5);
}

/* checked state */
.icons-facet-button.is-checked {
  --button-border-color: var(--fa-dk-blue) !important;
  --button-background: var(--fa-dk-blue) !important;
  --button-color: var(--white) !important;
  --icon-facet-affordance-color: var(--white);
  --icon-facet-count-color: var(--white);
}

.icons-facet-button.is-checked:hover,
.icons-facet-button.is-checked:active {
  --button-hover-color: var(--white) !important;
  --button-hover-background: var(--fa-dk-blue) !important;
}

/* icon facet tab */
.icons-facet-tab {
  --button-transition-duration: var(--timing-2xfast);
  --button-margin-bottom: 0;
  --button-border-radius: 0;
  --button-active-background: transparent !important; /* overriding button.flat */
  --link-color: var(--fa-md-gravy);
  --link-hover-color: var(--fa-dk-blue);
  --link-decoration-line: none;
  --link-hover-decoration-line: none;

  border-bottom: var(--border-width-md) solid transparent;
}

.icons-facet-tab:hover,
.icons-facet-tab:active {
  border-color: var(--link-hover-color);
}

.icons-facet-tab.active {
  --link-color: var(--fa-dk-blue);

  border-bottom: var(--border-width-md) solid var(--link-color);
  border-color: var(--link-color);
}

.icons-facet-tab.active:hover,
.icons-facet-tab.active:active {
  --link-hover-color: var(--fa-blue);

  border-bottom-color: var(--link-hover-color);
}

/* icon facet pills */
.icon-facet-pill,
.icon-facet-pill-clear {
  border-width: var(--border-width-sm);
  border-style: solid;
  border-color: var(--white);
}

.icon-facet-pill .icon-facet-pill-affordance {
  color: var(--fa-gravy);
}

.icon-facet-pill:hover .icon-facet-pill-affordance,
.icon-facet-pill:active .icon-facet-pill-affordance {
  color: var(--fa-red);
}

.icon-facet-pill-clear {
  --button-transition-duration: var(--timing-xfast);

  border-color: var(--fa-gravy);
}

.icon-facet-pill-clear:hover,
.icon-facet-pill-clear:active {
  border-color: var(--fa-dk-blue);
}

/* icon facet menu */
.wrap-icons-facets-menu {
  transition-property: opacity;
  transition-duration: var(--timing-xfast);
  transition-timing-function: ease-in-out;
  visibility: visible;
  height: auto;
  opacity: 1;
  margin-bottom: var(--spacing-3xl);
}

/* - .is-container-hidden is a workaround for https://github.com/mozilla/contain-facebook/issues/898 */
.wrap-icons-facets-menu.is-container-hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  margin-bottom: 0;
}

.icons-facets-menu .wrap-ad {
  order: 1;
}

.icons-facets-menu .icons-facets-group-editorial {
  order: 2;
}

/* categories need to target this ais-class since it wraps the list */
.icons-facets-menu .ais-RefinementList {
  order: 3;
}

/* icons results + listing */
.icons-results-summary-count {
  order: 1;
}

.icons-results-summary-pages {
  order: 2;
}

.icons-results-summary-facets {
  width: 100%;
  order: 3;
}

.icon-listing {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--icon-listing-roomy-size), 1fr));
  grid-gap: var(--icon-listing-roomy-gap-y) var(--icon-listing-roomy-gap-x);
  grid-auto-flow: row dense;
  justify-items: stretch;
}

.icon-listing .wrap-icon {
  width: 100%;
}

.icon-listing .wrap-view-more {
  grid-column: span 2;
  align-content: center;
}

.icon-listing.cheatsheet .wrap-view-more {
  grid-column: span 1;
}

.icon-listing.roomy .wrap-view-more {
  grid-column: span 1;
}

.icon-listing .icon .icon-name {
  width: 100%; /* to support text truncate */
  min-height: calc(var(--size-base) * 2.125);
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  color: var(--fa-md-gravy);
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon-listing .wrap-icon-detail {
  width: 100%;
  grid-column-start: 1;
  grid-column-end: -1;
}

.icon-listing .icon:hover .icon-name {
  color: inherit;
}

.icon-listing .icon:active .icon-name {
  color: var(--white);
}

/* icon listings display options */
.icon-listing .wrap-icon.with-top-tag {
  margin-top: 0;
}

.icon-listing.roomy .wrap-icon .icon .icon-name,
.icon-listing.compact .wrap-icon .icon .icon-name {
  margin-top: calc(var(--spacing-base) * 1.5); /* 24px */
}

.icon-listing.roomy {
  --icon-listing-roomy-gap-x: var(--spacing-xl); /* 24px */
  --icon-listing-roomy-gap-y: var(--spacing-xl); /* 24px */

  grid-auto-rows: var(--icon-listing-roomy-size);
}

.icon-listing.roomy .wrap-icon .icon i,
.icon-listing.roomy .wrap-icon .icon .svg-inline--fa {
  font-size: var(--icon-listing-roomy-icon-size); /* 36px */
}

/* reset icon size for close buttons on icon listing with close actions - WA fix */
.icon-listing .wrap-icon .icon .close i,
.icon-listing .wrap-icon .icon .close .svg-inline--fa {
  font-size: var(--font-size-base) !important;
}

.icon-listing.roomy .wrap-icon .icon .icon-name {
  font-size: var(--icon-listing-roomy-font-size); /* 14px */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--size-base) * 2.125);
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon-listing.compact {
  --icon-listing-roomy-gap-x: var(--spacing-base); /* 16px */
  --icon-listing-roomy-gap-y: var(--spacing-base); /* 16px */
  grid-template-columns: repeat(auto-fill, minmax(var(--icon-listing-compact-size), 1fr));
  grid-auto-rows: var(--icon-listing-compact-size);
}

.icon-listing.compact .wrap-icon .icon i,
.icon-listing.compact .wrap-icon .icon .svg-inline--fa {
  font-size: var(--icon-listing-compact-icon-size); /* 32px */
}

.icon-listing.compact .wrap-icon .icon .icon-name {
  font-size: var(--icon-listing-compact-font-size); /* 12px */
}

.icon-listing.cheatsheet .wrap-icon .icon {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.icon-listing.cheatsheet {
  --icon-listing-roomy-gap-x: calc(var(--spacing-base) * 0.875); /* 14px */
  --icon-listing-roomy-gap-y: calc(var(--spacing-base) * 0.875); /* 14px */

  grid-template-columns: repeat(auto-fill, minmax(var(--icon-listing-cheatsheet-size), 1fr));
}

.icon-listing.cheatsheet .wrap-icon .icon i,
.icon-listing.cheatsheet .wrap-icon .icon .svg-inline--fa {
  font-size: var(--icon-listing-cheatsheet-icon-size); /* 32px */
}

.icon-listing.cheatsheet .wrap-icon .icon .icon-name {
  width: initial;
}

.icon-listing.cheatsheet .wrap-icon .icon .icon-name {
  display: flex;
  align-items: center;
  font-size: var(--icon-listing-cheatsheet-font-size); /* 12px */
  margin-left: 10px;
  text-align: left;
}

.icon-listing.cheatsheet .wrap-icon.with-top-tag .tag {
  --top-tag-top: calc(var(--spacing-base) * (-0.8)); /* Adjusts Pro Tag positioning for cheatsheet results layout */
}

/* icon details */
.modal .icon-detail-modal-content {
  --card-vertical-padding: 0;
  --card-horizontal-padding: 0;
}

.icon-actions .icon-action-svg-download {
  flex-grow: 0;
  flex-shrink: 1;
}

.icon-actions .icon-action-download {
  flex-grow: 1;
}

.icon-actions .icon-action-start-using {
  flex-grow: 1;
}

.icon-details-preview {
  transition-property: background-color, border-color, color;
  transition-duration: var(--timing-xfast);
  transition-timing-function: ease-in-out;
}

.icon-details-preview-rendering {
  font-size: var(--icon-details-preview-font-size);
  line-height: var(--icon-details-preview-line-height);
}

.icon-details-styling .select-custom .select-custom-icon {
  top: 50%;
  transform: translateY(-50%);
}

/* needed to override default  buttons group border - WA fix */
.icon-details-styling .select-icon-style-button:hover,
.icon-details-styling .select-icon-style-button.active,
.icon-details-styling .select-icon-style-button:active {
  border-color: var(--fa-gravy);
}

.icon-detail-grid-preview {
  --order: 1;
}

.icon-detail-use {
  --order: 3;
}

.icon-detail-styling-tabs {
  --order: 2;
}

.icon-details-add-styling {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: var(--spacing-sm);
  grid-column-gap: var(--spacing-xl);
}

.icon-details-styling-color {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.icon-details-styling-rotate {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.icon-details-styling-size {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.icon-details-styling-flip {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

.icon-details-styling-flip .custom-radio-button-set.disabled,
.icon-details-styling-rotate .custom-radio-button-set.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.icon-details-animation-button {
  flex: 1 0 45%;
}

.styling-color-input {
  height: var(--spacing-4xl);
}

.icon-code-snippet .code-snippet-secondary-tab {
  --button-color: var(--fa-blue);
  --button-background: transparent;
  --button-hover-background: var(--fa-dk-navy);
  --button-border-radius: var(--border-radius-sm);
  --button-transition-duration: var(--timing-xfast);

  border-right: var(--border-width-sm) var(--border-style) var(--fa-navy);
  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
}

.icon-code-snippet .code-snippet-secondary-tab[aria-selected='true'] {
  --button-color: var(--white);
  --button-hover-color: var(--white);
  --button-background: var(--fa-navy);
  --button-hover-background: var(--fa-navy);
}

.icon-code-snippet .codeblock pre {
  padding: var(--icon-code-snippet-padding-horizontal);
}

.icon-code-snippet .codeblock code {
  padding-right: var(--icon-code-snippet-padding-horizontal);
}

/* Icon Wizard */
.icon-wizard-grid-preview {
  --order: 1;
}

.icon-wizard-use {
  --order: 3;
}

.icon-wizard-modifiers {
  --order: 2;
}

.icon-customize .icon-action-modifier {
  --button-color: var(--fa-dk-blue);
  --button-border-width: 2px;

  border-color: var(--fa-lt-gravy);
}

.icon-customize .icon-action-modifier.active,
.icon-customize .icon-action-modifier:active {
  /* --button-active-background: color-mix(in oklab, var(--fa-blue) 25%, transparent); */
  /* --button-active-color: var(--fa-navy); */
  --button-active-border-width: 2px;

  border-color: var(--fa-navy);
}

.icon-customize .checkbox-make-duotone {
  --button-background: var(--fa-lt-gravy);
  --button-color: var(--fa-dk-blue);
  --label-color: var(--fa-dk-blue);
  --link-color: var(--fa-dk-blue);

  border-width: 2px;
  border-color: var(--fa-lt-gravy);
}

.icon-customize .checkbox-make-duotone[disabled] {
  --button-background: none;
  --label-disabled-color: var(--fa-navy);
  opacity: 0.5;
}

.checkbox-make-duotone:hover {
  --button-background: var(--fa-lt-gravy);
  --button-color: var(--fa-dk-blue);
  --label-color: var(--fa-dk-blue);
  --link-color: var(--fa-dk-blue);
}

.checkbox-make-duotone.active {
  --button-background: color-mix(in oklab, var(--fa-blue) 25%, transparent);
  --button-color: var(--fa-dk-blue);
  --label-color: var(--fa-dk-blue);
  --button-border-width: 2px;

  border-color: var(--fa-navy);
}

/* icon landing */
.icon-landing .theme-blue {
  --example-color: var(--fa-blue);
  --example-color-alt: var(--fa-dk-blue);
  --example-text-color: var(--white);
}

.icon-landing .theme-purple {
  --example-color: var(--fa-violet);
  --example-color-alt: var(--fa-dk-violet);
  --example-text-color: var(--white);
}

.icon-landing .theme-teal {
  --example-color: var(--fa-teal);
  --example-color-alt: var(--fa-dk-teal);
  --example-text-color: var(--white);
}

.icon-landing .theme-yellow {
  --example-color: var(--fa-yellow);
  --example-color-alt: var(--fa-dk-yellow);
  --example-text-color: var(--fa-navy);
}

/* icon categories */
.all-categories-grid {
  --list-style-type: none;
  --padding-left: 0;
  --margin-top: 0;
  --margin-bottom: 0;

  display: grid;
  grid-template-rows: repeat(10, auto);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-lg);
}

.all-categories-grid li:nth-child(5n + 1) .header {
  color: var(--fa-blue);
}

.all-categories-grid li:nth-child(5n + 2) .header {
  color: var(--fa-teal);
}

.all-categories-grid li:nth-child(5n + 3) .header {
  color: var(--fa-purple);
}

.all-categories-grid li:nth-child(5n + 4) .header {
  color: var(--fa-violet);
}

.category-landing {
  --category-color: var(--fa-blue);
  --category-color-alt: var(--fa-dk-blue);
  --category-text-color: var(--white);
}

/* making sure content can be selected + interacted with above collage/scene */
.category-landing .category-header .content {
  z-index: var(--depth-1);
}

/* icon collage/scene */

/* safe to use overflow: clip -  to visually crop collage */
@supports (overflow: clip) {
  .category-landing .category-header .container-scene {
    overflow-y: clip;
  }
}

/* not safe to use overflow: clip - set app header to be visually in front of collage */
@supports not (overflow: clip) {
  .category-landing .app-header {
    z-index: var(--depth-1);
  }
}

.category-landing .category-header .scene {
  inset: 0;
}

.category-landing .theme-blue {
  --category-color: var(--fa-blue);
  --category-color-alt: var(--fa-dk-blue);
  --category-text-color: var(--white);
  --category-color-transparent: rgba(0, 128, 221, 0.295);
}

.category-landing .theme-purple {
  --category-color: var(--fa-violet);
  --category-color-alt: var(--fa-dk-violet);
  --category-text-color: var(--white);
  --category-color-transparent: rgba(110, 62, 225, 0.3);
}

.category-landing .theme-teal {
  --category-color: var(--fa-teal);
  --category-color-alt: var(--fa-dk-teal);
  --category-text-color: var(--white);
  --category-color-transparent: rgba(0, 169, 116, 0.3);
}

.category-landing .theme-yellow {
  --category-color: var(--fa-yellow);
  --category-color-alt: var(--fa-dk-yellow);
  --category-text-color: var(--fa-navy);
  --category-color-transparent: rgba(255, 172, 0, 0.277);
}

.category-landing .category-header {
  position: relative;
  background-color: var(--category-color);
}

/* icon discovery */
.icons-landing-stats {
  gap: var(--spacing-lg);
}

.icons-landing .tab-content {
  scroll-margin-top: var(--spacing-8xl); /* adding visual whitespace to tab-content when jumped to via an anchor link */
}

.icons-landing .pack-card-style {
  --pack-card-style-hover-color: var(--blue1);
  overflow-x: hidden;
  transition: background var(--timing-xfast) ease-in-out, color var(--timing-xfast) ease-in-out;
}

.icons-landing .pack-card-style:hover,
.icons-landing .pack-card-style:focus {
  background-color: var(--pack-card-style-hover-color);
  color: var(--link-color);
}

.icons-landing .pack-card-style-details {
  background: linear-gradient(to right, var(--pack-card-style-hover-color) 0%, var(--pack-card-style-hover-color) 80%, transparent 100%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  padding: var(--card-vertical-padding) var(--card-horizontal-padding);
}

.icons-landing .pack-card-style:hover .pack-card-style-details,
.icons-landing .pack-card-style:focus .pack-card-style-details {
  opacity: 1;
}

.icons-landing .categories-listing,
.icons-landing .categories-listing-featured {
  display: grid;
  gap: var(--spacing-2xl);
}

.icons-landing .categories-listing {
  --categories-listing-gap: calc(var(--spacing-base) * 9); /* ~144px */
  grid-template-columns: repeat(auto-fit, minmax(var(--categories-listing-gap), 1fr));
  grid-auto-rows: minmax(var(--categories-listing-gap), auto);
}

.icons-landing .categories-listing-featured {
  grid-template-columns: repeat(2, 1fr);
}

.icons-landing .categories-listing-featured .visuals {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-xl);
  justify-items: center;
}

.icons-landing .action-scale {
  transition: transform var(--timing-xfast) ease-in-out, color var(--timing-xfast) ease-in-out, border-color var(--timing-xfast) ease-in-out;
}

.icons-landing .action-scale:active {
  transform: scale(0.925);
}

/* transitions for icon packs */
.icons-landing .pack-transition-enter-active,
.icons-landing .pack-transition-leave-active {
  transition: transform var(--timing-xfast) cubic-bezier(var(--pack-listing-transition-curve)), opacity var(--timing-xfast) ease;
}

.icons-landing .pack-transition-enter-from,
.icons-landing .pack-transition-leave-to {
  transform: scale(0.95); /* slightly shrink when entering or leaving */
  opacity: 0;
}

.icons-landing .pack-transition-enter-to,
.icons-landing .pack-transition-leave-from {
  transform: scale(1);
  opacity: 1;
}

/* Moving Transition (for reordering) */
.icons-landing .pack-transition-move {
  transition: transform var(--timing-xfast) cubic-bezier(var(--pack-listing-transition-curve)); /* bounce-like transition */
}

.icon-discovery .featured-style-grid {
  display: grid;
  grid-template-rows: repeat(3, 2rem);
  grid-template-columns: repeat(6, 3rem);
  grid-gap: var(--spacing-lg);
  grid-auto-flow: column;
  grid-auto-rows: 2rem;
  grid-auto-columns: 3rem;
  justify-content: end;
  align-items: center;
  overflow: hidden;
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
}

.icon-discovery .style-duotone .featured-style-grid {
  grid-template-columns: repeat(12, 3rem);
}

.icon-discovery .brands-icon-grid {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-gap: var(--spacing-xs) var(--spacing-2xl);
  overflow: hidden;
}

.icon-discovery .category-feature-sm {
  --list-style-type: none;
  --padding-left: 0;
  --margin-top: 0;
  --margin-bottom: 0;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--spacing-lg);
}

.icon-discovery .search-suggestion-button {
  --button-border-radius: var(--border-radius-pill);
  --button-hover-background: var(--fa-dk-blue);
  --button-hover-color: var(--white);
  --button-color: var(--fa-dk-gravy);

  transition-property: background-color;
  transition-duration: var(--timing-xfast);
  transition-timing-function: ease-in-out;
  font-weight: var(--font-weight-normal);
  vertical-align: baseline;
}

.icon-discovery .search-suggestion-button .icon,
.icon-discovery .search-suggestion-button .hover-icon,
.icon-discovery .search-suggestion-button .hover-icon-ee {
  transition-property: opacity;
  transition-duration: var(--timing-2xfast);
  transition-timing-function: ease-in-out;
}

.icon-discovery .search-suggestion-button .icon {
  display: inline-block;
  opacity: 1;
}

.icon-discovery .search-suggestion-button:hover .icon,
.icon-discovery .search-suggestion-button:active .icon {
  display: none;
  opacity: 0;
}

.icon-discovery .search-suggestion-button .hover-icon,
.icon-discovery .search-suggestion-button .hover-icon-ee {
  display: none;
  opacity: 0;
}

.icon-discovery .search-suggestion-button:hover .hover-icon,
.icon-discovery .search-suggestion-button:active .hover-icon {
  display: inline-block;
  opacity: 1;
}

.icon-discovery .search-suggestion-button.easter-egg {
  --button-hover-background: var(--fa-dk-purple);
}

.icon-discovery .search-suggestion-button.easter-egg:hover .icon,
.icon-discovery .search-suggestion-button.easter-egg:active .icon,
.icon-discovery .search-suggestion-button.easter-egg:hover .hover-icon,
.icon-discovery .search-suggestion-button.easter-egg:active .hover-icon {
  display: none;
  opacity: 0;
}

.icon-discovery .search-suggestion-button.easter-egg:hover .hover-icon-ee,
.icon-discovery .search-suggestion-button.easter-egg:active .hover-icon-ee {
  display: inline-block;
  opacity: 1;
}

/* docs */
.docs-search-decorative {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.docs-version-selector .version-select {
  --input-padding: var(--spacing-sm) var(--spacing-4xl) var(--spacing-sm) 0;

  font-weight: var(--input-font-weight);
  width: 9ch; /* setting width to support semantic version values */
}

.docs-nav-section-title {
  --headings-margin-top: var(--spacing-md);
  --h2-font-size: var(--font-size-xl);
  --link-color: var(--fa-gravy);
  --link-font-weight: var(--font-weight-bold);
}

.docs-nav-section-title.section-web {
  --headings-margin-top: 0;
}

.docs-nav-subsection-title,
.docs-page-nav-title {
  --headings-margin-top: var(--spacing-md);
  --margin-bottom: 0;
  --headings-font-weight: 600;

  color: var(--fa-md-gravy);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-xl);
}

.docs-main-nav li {
  line-height: 1.2;
}

.docs-main-nav a {
  --link-color: var(--fa-navy);
  --link-decoration-line: none;
  --link-display: block;
}

.docs-main-nav a.current {
  --link-color: var(--white);
  --link-hover-color: var(--white);

  background-color: var(--fa-dk-blue);
  border-radius: var(--border-radius-sm);
}

.docs-main-nav .docs-nav-section-title a {
  --link-color: var(--fa-navy);
}

/* inverted style for older docs */
.inverted .docs-main-nav .docs-nav-section-title a {
  --link-color: var(--fa-white);
}

.docs-page-nav a {
  --link-color: var(--fa-md-gravy);
  --link-decoration-line: none;
}

.docs-page-nav a.current {
  --link-color: var(--body-color);

  font-weight: 600;
}

.docs-page-nav li {
  --list-item-margin-bottom: var(--spacing-sm);
}

.nav-section-troubleshoot .docs-nav-section-title {
  display: none;
}

.nav-section-troubleshoot .docs-nav-section-list {
  display: block;
}

/* docs landings */
.docs-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-gap: var(--spacing-lg);
  grid-auto-flow: row;
}

.docs-card-grid .upload .background-icons .icon-decorative {
  transition: color ease-in-out var(--timing-2xfast);
  color: var(--fa-dk-gravy);
}

.docs-card-grid .upload:hover {
  background-color: var(--fa-blue);
}

.docs-card-grid .upload:hover .background-icons .icon-decorative {
  color: var(--fa-lt-gravy);
}

.docs-card-grid.home-grid {
  grid-gap: var(--spacing-2xl);
}

.docs-search {
  order: 2;
}

/* Docsearch from Algolia */
.docs-search .algolia-autocomplete {
  display: block !important;
}

.docs-search .algolia-autocomplete .ds-dropdown-menu {
  min-width: 250px;
  border-radius: var(--border-radius-md);
  box-shadow: none;
}

.docs-search .algolia-autocomplete .ds-dropdown-menu [class^='ds-dataset-'] {
  border-color: var(--fa-navy);
  border-width: 2px;
  border-radius: var(--border-radius-md);
}

.docs-search .algolia-autocomplete.algolia-autocomplete-left .ds-dropdown-menu::before,
.docs-search .algolia-autocomplete.algolia-autocomplete-right .ds-dropdown-menu::before {
  left: 2em;
}

.docs-search .algolia-autocomplete .ds-dropdown-menu::before {
  border-color: var(--fa-navy);
  border-width: 2px;
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion {
  padding: 0;
  text-decoration-line: none;
}

/* Main page/Result header */
.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
  float: none;
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
  padding: 0;
}

/* Hover state */
.docs-search .algolia-autocomplete .ds-cursor .algolia-docsearch-suggestion--wrapper {
  background-color: rgba(88, 194, 255, 0.2);
  border-radius: var(--border-radius-sm);
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  padding: var(--spacing-xs);
  color: var(--fa-md-gravy);
  font-size: var(--font-size-sm);
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column::before,
.docs-search .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column::after {
  display: none;
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--subcategory-column {
  opacity: 1;
  float: none;
  width: auto;
  padding: var(--spacing-3xs) var(--spacing-xs) 0 var(--spacing-xs);
  font-size: var(--font-size-md);
  text-align: left;
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion .algolia-docsearch-suggestion--content {
  float: none;
  width: auto;
  border-radius: var(--border-radius-sm);
  padding: 0 var(--spacing-3xs) var(--spacing-3xs) var(--spacing-xs);
  color: var(--fa-md-gravy);
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--content::before {
  background: transparent;
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column-text {
  color: var(--fa-navy);
  font-weight: 600;
}

.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column-text:hover {
  color: var(--fa-dk-blue);
}

/* Page section title */
.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--title {
  color: var(--fa-md-gravy);
}

/* Description text */
.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--text {
  color: var(--fa-md-gravy);
}

/* Highlighted text */
/* underline color */
.docs-search
  .algolia-autocomplete
  .algolia-docsearch-suggestion--category-header
  .algolia-docsearch-suggestion--category-header-lvl0
  .algolia-docsearch-suggestion--highlight,
.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  box-shadow: inset 0 -2px 0 0 var(--fa-blue);
}
/* background color highlight in section title */
.docs-search .algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: var(--fa-dk-navy);
  background: rgba(88, 194, 255, 0.2);
}

/* remove hover background color */
.docs-search .ds-cursor .algolia-docsearch-suggestion--content {
  background-color: transparent !important;
}

/* algolia footer */
.docs-search .algolia-autocomplete .algolia-docsearch-footer {
  transition: var(--timing-xfast) all ease-in-out;
  filter: saturate(70%);
  opacity: 0.5;
  margin-top: 16px;
}

.docs-search .algolia-autocomplete .algolia-docsearch-footer:hover {
  filter: saturate(100%);
  opacity: 1;
}

.docs-search .ds-cursor .algolia-docsearch-suggestion--content {
  background-color: rgba(0, 234, 188, 0.15) !important;
}

/* from VuePress */
/* fix prismjs / web awesome tag conflict */
.codeblock .tag,
code .tag {
  --tag-padding: initial;
  --tag-letter-spacing: initial;
  --tag-text-transform: none;
  --tag-background: transparent;
  --tag-font-size: var(--font-size-base);
  --tag-font-weight: var(--font-weight-normal);
}

/* prismjs */
code[class*='language-'],
pre[class*='language-'] {
  text-shadow: none !important;
  margin: 0 4em 0 0; /* adds room on right for copy button */
}

pre[class*='language-']::selection,
pre[class*='language-'] ::selection,
code[class*='language-']::selection,
code[class*='language-'] ::selection {
  background: rgba(255, 255, 255, 0.3);
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  position: relative;
  /* The prism.js highlighter does some weird DOM stuff and this is needed to still allow */
  /* text to be highlighted. */
  z-index: 1;
  background: none;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata,
.token.punctuation {
  color: var(--fa-gravy);
}

.token.tag,
.token.attr-name,
.token.namespace,
.token.deleted {
  color: var(--fa-lt-gravy);
}

.token.function-name {
  color: var(--fa-blue);
}

.token.boolean,
.token.number,
.token.function {
  color: var(--fa-yellow);
}

.token.property,
.token.class-name,
.token.constant,
.token.symbol {
  color: var(--fa-gravy);
}

.token.selector,
.token.important,
.token.atrule,
.token.keyword,
.token.builtin {
  color: var(--fa-red);
}

.token.string,
.token.char,
.token.attr-value,
.token.regex,
.token.variable,
.token.inserted {
  color: var(--fa-teal);
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  background: none;
  color: var(--fa-white);
}

/* resetting vertical-align to avoid collision with Web Awesome "tag" element */
.token.tag {
  vertical-align: baseline;
}

/* code-block */
.codeblock .tag,
code .tag {
  --tag-padding: initial;
  --tag-letter-spacing: initial;
  --tag-text-transform: none;
  --tag-background: transparent;
  --tag-font-size: var(--font-size-base);
  --tag-font-weight: var(--font-weight-normal);

  white-space: intial;
}

/* used for Tokens in Account */
.api-token .codeblock {
  margin: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.api-token .token-details {
  border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
}

.api-token.expired .token-details {
  opacity: 0.5;
  border-radius: 0;
}

.api-token.expired .codeblock {
  background-color: var(--fa-gravy);
}

.api-token.expired .codeblock code {
  --code-color: var(--white);
}

.api-token.expired .copy-button {
  display: none;
}

.codeblock-container .highlight-lines {
  position: absolute;
  user-select: none;
  top: calc((var(--spacing-md) + var(--spacing-lg)) / 2);
  left: 0;
  width: 100%;
  padding-top: var(--spacing-md);
}

.codeblock-container .highlight-lines .highlighted {
  background-color: rgba(255, 255, 255, 0.1);
  /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="rgba(255, 255, 255, 0.5)" d="M320 256c0 88.37-71.63 160-160 160s-160-71.63-160-160s71.63-160 160-160S320 167.6 320 256z"></path></svg> */
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48cGF0aCBmaWxsPSJyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNSkiIGQ9Ik0zMjAgMjU2YzAgODguMzctNzEuNjMgMTYwLTE2MCAxNjBzLTE2MC03MS42My0xNjAtMTYwczcxLjYzLTE2MCAxNjAtMTYwUzMyMCAxNjcuNiAzMjAgMjU2eiI+PC9wYXRoPjwvc3ZnPg==);
  background-position: 0.75em 60%;
  background-size: 0.25em;
  background-repeat: no-repeat;
}

.codeblock-tabbed .code-snippet-tab {
  --button-color: var(--fa-blue);
  --button-font-weight: var(--font-weight-normal);
  --button-hover-background: var(--fa-dk-navy);
  --button-active-background: transparent;
  --button-border-radius: var(--border-radius-sm);
  --button-transition-duration: var(--timing-xfast);

  transition-property: background-color, color;
  transition-timing-function: ease-in-out;
}

.codeblock-tabbed .code-snippet-tab.active {
  --button-active-color: var(--white);
  --button-hover-color: var(--white);
  --button-font-weight: var(--font-weight-bold);
  --button-hover-background: transparent;
  --button-active-background: transparent;
}

.codeblock-tabbed .codeblock-container .highlight-lines {
  top: var(--spacing-4xs);
  padding-top: var(--spacing-md);
}

.codeblock-caption {
  text-align: center;
  color: var(--fa-md-gravy);
  margin-top: -1em;
  margin-bottom: 2em;
}

/* sticky elements  -see StickyElementMixin */
.sticky-element {
  position: sticky;
  z-index: var(--depth-1);
}

.sticky-element.sticky-element-end {
  inset-block-end: var(--sticky-element-offset);
}

.sticky-element.sticky-element-start {
  inset-block-start: var(--sticky-element-offset);
}

.sticky-actions {
  background: var(--fa-lt-gravy);
  border-block-start: var(--hr-border-width) var(--hr-border-style) var(--hr-border-color);
  margin-inline: calc(-2 * var(--grid-gutter-x-width));
  padding-block-start: var(--spacing-md);
  padding-block-end: var(--spacing-lg);
  padding-inline: calc(var(--grid-gutter-x-width) * 2);
  transition-duration: var(--timing-xfast);
  transition-property: all;
  transition-timing-function: ease-in-out;
}

.sticky-actions.is-stuck {
  background: var(--white);
  border-radius: var(--card-border-radius);
}

/* markdown docs content styles */
/* vuepress generated anchor link icons */
.header-anchor {
  --link-decoration-line: none;

  opacity: 0;
  margin-left: -1.25em;
  padding-right: 0.25em;
  vertical-align: middle;
}

/* vuepress generated outbound link icon */
.content__default .icon.outbound {
  color: var(--link-color);
  margin-left: var(--spacing-5xs);
}

h1:hover .header-anchor,
h2:hover .header-anchor,
h3:hover .header-anchor,
h4:hover .header-anchor,
h5:hover .header-anchor,
h6:hover .header-anchor {
  opacity: 1;
}

.content__default hr {
  --hr-margin-y: var(--spacing-2xl);
  --hr-border-color: var(--fa-lt-gravy);
}

.content__default ol li,
.content__default ul li {
  --list-item-margin-bottom: var(--spacing-xs);
}

.content__default ol,
.content__default ul {
  --list-margin-bottom: var(--spacing-2xl);
}

.content__default table {
  --table-margin-bottom: var(--spacing-4xl);
}

.content__default th.width-30 {
  width: 30%;
}

.content__default th.width-60 {
  width: 60%;
}

.content__default blockquote {
  margin: var(--spacing-lg) 0;
  border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
  padding: 1em;
  background-color: var(--white);
}

.content__default blockquote + h2,
.content__default blockquote + h3 {
  margin-top: var(--spacing-sm);
}

.content__default h2 + h3,
.content__default h2 + h4 {
  margin-top: var(--spacing-sm);
}

.content__default blockquote:first-child {
  display: inline-block;
  margin: 0 0 var(--spacing-md) 0;
  border: 0;
  background-color: transparent;
  padding: 0 0 var(--spacing-2xs) 0;
  font-style: normal;
  font-size: var(--font-size-lg);
  font-family: var(--font-family-base);
}

.content__default img.with-border {
  --border-color: var(--fa-navy);
}

.content__default p img {
  border-radius: var(--spacing-2xs);
}

.content__default .codeblock-container {
  margin-bottom: var(--spacing-xl);
}

/* docs - styling cards */
.content__default .styling-card {
  display: flex;
  flex-direction: column;
}

.content__default .styling-card .header {
  flex-grow: 1;
  height: 10rem;
}

.content__default .styling-card .section {
  flex-grow: 1;
}

.content__default .styling-card.with-top-tag {
  margin-top: 0;
}

.content__default .styling-card.card.with-top-tag .section {
  margin-top: 0;
}

.content__default .styling-card.layer .fa-heart {
  color: var(--fa-md-gravy);
}

.content__default .styling-card.layer:hover .fa-heart {
  color: var(--fa-red);
}

.content__default .styling-card.counter .count {
  background-color: var(--fa-dk-gravy);
  color: var(--fa-white);
}

.content__default .styling-card.counter:hover .count {
  background-color: var(--fa-teal);
  color: var(--fa-navy);
}

.content__default .styling-card.duotone:hover .fa-crow {
  --fa-secondary-opacity: 1;
  --fa-primary-color: var(--fa-teal);
  --fa-secondary-color: var(--fa-dk-yellow);
}

.content__default .styling-card.duotone-d:hover .fa-cookie-bite {
  --fa-secondary-opacity: 1;
  --fa-primary-color: saddlebrown;
  --fa-secondary-color: burlywood;
}

/* affiliates */
.affiliates-sales-month {
  flex-grow: 1;
}

.affiliate-hero .codeblock {
  margin-top: 0;
}

/* cdn */
.cdn-configure-form .choose-version {
  height: auto;
}

/* icon requests leaderboard */
/* standardizes column width of results table */
.requests-col-sm {
  width: 10%;
}

/* version-specific styling */

/* use FA5 to render set up message bullets */
.version-v5 .message.docs li::before {
  font-family: 'Font Awesome 5 Pro';
}

/* overriding stacking collision b/t web fonts + svg styling  */
.version-v5 .fa-stack .svg-inline--fa.fa-stack-2x {
  font-size: inherit;
}

/* hiding focus ring on .question-mark-block-button easter egg only when directly clicked/selected  */
.question-mark-block-button:is(:focus:not(:focus-visible), .focus, .focused),
.question-mark-block-hud:is(:focus:not(:focus-visible), .focus, .focused) {
  --focus-outline: none;
}

/* animations */
@keyframes slideBounceFadeDown {
  0% {
    transform: translateY(-32px);
    opacity: 0.8;
  }
  60% {
    transform: translateY(4px);
    opacity: 1;
  }
  80% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideBounceFadeUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  30% {
    transform: translateY(-4px);
  }
  60% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-28px);
    opacity: 0.8;
  }
}

.slideBounceFadeDown {
  animation: slideBounceFadeDown var(--timing-xfast) cubic-bezier(0.33, 1, 0.68, 1) both;
}

.slideBounceFadeUp {
  animation: slideBounceFadeUp var(--timing-xfast) cubic-bezier(0.45, 0, 0.55, 1) forwards;
}

@keyframes questionMarkBlockBounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-0.25rem);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.questionMarkBlockBounce {
  animation: questionMarkBlockBounce var(--question-mark-block-bounce-duration, var(--timing-xfast)) ease-in-out;
}

@keyframes questionMarkCoinkBounce {
  0% {
    transform: translateX(-50%) translateY(-150%);
    opacity: 1;
  }
  30% {
    transform: translateX(-50%) translateY(-200%);
  }
  40% {
    transform: translateX(-50%) translateY(-200%);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-175%);
    opacity: 0;
  }
}

.questionMarkCoinkBounce {
  animation: questionMarkCoinkBounce var(--question-mark-coin-bounce-duration, var(--timing-slow)) ease-in-out;
}

@keyframes impersonationFade1 {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes impersonationFade2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* impersonation animation */
.impersonation-icon {
  animation-duration: var(--timing-2xslow);
  animation-iteration-count: infinite;
}

.impersonation-icon:nth-child(1) {
  animation-name: impersonationFade1;
}

.impersonation-icon:nth-child(2) {
  animation-name: impersonationFade2;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cycle {
  animation: fadeInOut var(--timing-2xslow);
  opacity: 0;
}

.cycler {
  display: inline-block;
  min-width: 5em;
  text-align: center;
  /* --animate-duration: var(--timing-2xslow); */
}

.cycler-container {
  border-bottom: var(--border-width-sm) solid var(--fa-dk-yellow);
  padding-bottom: var(--spacing-7xs);
}

/* Support */
.support-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-gap: var(--spacing-lg);
  grid-auto-flow: row;
}

/* custom hover border for support nav section headers */
.support-nav summary {
  --border-color: transparent;
}

.support-nav summary:hover {
  --border-color: var(--fa-gravy);
}

/* kits */
.kit-wizard .kit-wizard-style-card-grid {
  display: grid;
  grid-gap: var(--spacing-xl);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

/* WA fix - add box-shadow color */
.kit-wizard .kit-style-card {
  --button-box-shadow-color: var(--fa-gravy);
  --button-box-shadow: 0 var(--button-box-shadow-width) 0 var(--button-box-shadow-color);
}

.kit-wizard .kit-style-card:hover {
  --button-box-shadow-color: var(--fa-navy);
  /* WA fix - connect card link hover background color */
  background-color: var(--card-link-hover-background);
}

.kit-summary-stats {
  gap: var(--spacing-2xs);
}

/* having to reset/override icon size in a custom tag element - WA fix */
.kit-summary-stats .kit-summary-stat .svg-inline--fa {
  font-size: initial;
}

/* custom Kit avatar color radio select */
.custom-radio-button-set input + label {
  --option-border-color: var(--fa-lt-gravy);
  --option-background-color: var(--white);
  --option-color: inherit;

  border: 2px solid var(--option-border-color);
  background-color: var(--option-background-color);
  color: var(--option-color);
  cursor: pointer;
}

.custom-radio-button-set input + label:hover {
  --option-background-color: var(--fa-navy);
  --option-color: var(--white);
}

.custom-radio-button-set input:focus + label {
  box-shadow: 0 0 0 var(--focus-outline-offset) var(--body-background),
    0 0 0 calc(var(--focus-outline-width) + var(--focus-outline-offset)) var(--focus-outline-color);
}

.custom-radio-button-set input:checked + label,
.custom-radio-button-set .active label,
.custom-radio-button-set .active input {
  border: 2px solid var(--fa-navy);
}

.kit-icon-styles-listing .card.link:hover button {
  text-decoration: underline;
}

.kit-listing-card .edit-icon {
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--timing-2xfast);
  transition-timing-function: ease-in-out;
}

.kit-listing-card .edit-avatar-button:hover .edit-icon {
  opacity: 0.65;
}

/* WA-add: allow text-decoration overrides on buttons that are links/links that are buttons? */
.kits-onboarding-task {
  text-decoration-style: var(--link-decoration-style);
}

.kits-onboarding-task:hover,
.kits-onboarding-task:focus {
  text-decoration-style: var(--link-hover-decoration-style);
}

/* default */
.kits-onboarding-task .focus-icon,
.kits-onboarding-task .complete-icon {
  display: none;
}

/* when a task is static */
.kits-onboarding-task.is-static .focus-icon {
  display: inline-block;
}

.kits-onboarding-task.is-static .default-icon {
  display: none;
}

/* TODO: enable + revisit these states if/when we make this a real check/task list */

/* when a task is focused on */
/* .kits-onboarding-task.is-focused .focus-icon,
.kits-onboarding-task:hover .focus-icon,
.kits-onboarding-task:focus .focus-icon {
  display: inline-block;
} */

/* when a task is focused on */
/* .kits-onboarding-task.is-focused .default-icon,
.kits-onboarding-task:hover .default-icon,
.kits-onboarding-task:focus .default-icon {
  display: none;
} */

/* when a task is complete */
/* .kits-onboarding-task.is-complete .complete-icon {
  display: inline-block;
}

.kits-onboarding-task.is-complete .default-icon,
.kits-onboarding-task.is-complete .focus-icon {
  display: none;
}

.kits-onboarding-task.is-complete .task-label {
  text-decoration: line-through;
  color: var(--fa-md-gravy);
} */

/* custom icons */
.custom-icon-upload-card .section {
  border-top: var(--card-section-border);
}

/* if there's an error, don't display border */
.custom-icon-upload-card.has-error .section {
  border: none !important;
}

.custom-icon-upload-card .wrap-custom-icon-upload-preview {
  /* magic number to display an icon preview window at 8x base font size ~160px */
  width: var(--spacing-9xl);
}

.custom-icon-upload-preview .icon-grid {
  --icon-grid-square-color: rgba(30, 48, 80, 0.15);

  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--timing-xfast);
  transition-timing-function: ease-in-out;
  background-image: linear-gradient(
      45deg,
      var(--icon-grid-square-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--icon-grid-square-color) 75%,
      var(--icon-grid-square-color) 100%
    ),
    linear-gradient(
      45deg,
      var(--icon-grid-square-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--icon-grid-square-color) 75%,
      var(--icon-grid-square-color) 100%
    );
  background-position: 0 0, 8px 8px;
  background-size: 16px 16px;
  font-size: 128px;
  line-height: 102.4px;
}

.custom-icon-upload-preview:hover .icon-grid,
.custom-icon-upload-preview:focus .icon-grid {
  opacity: 1;
}

/* hack to allow inner interactive elements on clickable cards */
@supports (selector(:has(p))) {
  .card.link:has(a)::after {
    z-index: var(--depth--1);
  }
}

@supports not (selector(:has(p))) {
  .kit-listing-card.link::after {
    z-index: var(--depth--1);
  }
}

/* position the tag at the top of the tab on smaller viewports */
.kit-setup .quick-start .tab-tag {
  position: absolute;
  top: -1.2em;
  left: calc(50% - 3.5em);
}

.kit-setup .tab.active:hover {
  background-color: var(--white);
}

/* kit icon styles summary */
.kit-icon-styles-listing {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: var(--spacing-2xl);
}

.kit-subset-style-modal-content .subset-status {
  width: 100%;
}

/* can't target the custom property of this input contained in the wa-text-field component to make it work for affixed */
.kit-settings-add-domain input {
  margin-bottom: 0;
}

.kit-domains .kit-domain:hover,
.kit-domains .kit-domain:focus {
  background: var(--card-hover-background);
}

/* kit not found */
@keyframes kittLights1 {
  0% {
    opacity: 0.2;
  }
  12.5% {
    opacity: 1;
  }
  25% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes kittLights2 {
  0% {
    opacity: 0;
  }
  12.5% {
    opacity: 0.2;
  }
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes kittLights3 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.2;
  }
  37.5% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes kittLights4 {
  0% {
    opacity: 0;
  }
  37.5% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  62.5% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes kittLights5 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  62.5% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes kittLights6 {
  0% {
    opacity: 0;
  }
  62.5% {
    opacity: 0.2;
  }
  75% {
    opacity: 1;
  }
  87.5% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes kittLights7 {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.2;
  }
  87.5% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes kittLights8 {
  0% {
    opacity: 0;
  }
  87.5% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.kitt-light {
  background: var(--fa-dk-red);
  width: var(--spacing-4xs);
  height: var(--spacing-4xs);
  animation-duration: var(--timing-fast);
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.kitt-light:nth-child(1) {
  animation-name: kittLights1;
}
.kitt-light:nth-child(2) {
  animation-name: kittLights2;
}
.kitt-light:nth-child(3) {
  animation-name: kittLights3;
}
.kitt-light:nth-child(4) {
  animation-name: kittLights4;
}
.kitt-light:nth-child(5) {
  animation-name: kittLights5;
}
.kitt-light:nth-child(6) {
  animation-name: kittLights6;
}
.kitt-light:nth-child(7) {
  animation-name: kittLights7;
}
.kitt-light:nth-child(8) {
  animation-name: kittLights8;
}

/* transitions */
.transition-scale-fade-enter,
.transition-scale-fade-leave-active {
  opacity: 0;
}

.transition-scale-fade-content {
  transition: all var(--timing-xfast) ease;
}

.transition-scale-fade-enter .transition-scale-fade-content,
.transition-scale-fade-leave-active .transition-scale-fade-content {
  transform: scale(1.1);
}

/* custom - mobile only only */
@media (max-width: 768px) {
  /* makes modals wider for more readability */
  .modal .icon-detail-modal-content,
  .modal .pro-icon-modal-content,
  .modal .pro-feature-modal-content,
  .modal .about-frameworks-modal-content,
  .modal .kits-and-domains-modal-content,
  .modal .upsell-modal-content {
    --modal-max-width: none;
    margin-right: var(--spacing-xl);
    margin-left: var(--spacing-xl);
  }
}

/* custom - tablet and below only */
@media (max-width: 1151px) {
  .wrap-nav-menu {
    border-bottom: 2px solid var(--fa-lt-gravy);
    margin-bottom: var(--spacing-4xl);
  }

  /* - .is-container-hidden is a workaround for https://github.com/mozilla/contain-facebook/issues/898 */
  .wrap-nav-menu.is-container-hidden {
    display: none;
  }
}

/* custom > tablet */
@media (min-width: 768px) {
  .modal .web-awesome-modal-content {
    width: 460px;
    --modal-max-width: 460px;
  }

  .wa-kickstarter-video {
    width: 460px;
    height: 258px;
    margin: 0 auto;
    display: block;
  }

  /* general */
  .nudge:after {
    --nudge-pointer-display: block;
  }

  /* app header */
  .wrap-app-header {
    margin-left: calc(var(--spacing-md) * -1);
    margin-right: calc(var(--spacing-md) * -1);
  }

  .app-header-version-account {
    position: static;
    top: auto;
    bottom: auto;
    right: auto;
  }

  /* bandana */
  .bandana-v7-teaser .pancakes {
    width: 300px;
    bottom: 32%;
    right: 90%;
  }

  .bandana-v7-teaser .coffee {
    width: 250px;
    top: 20%;
    left: 85%;
  }

  .bandana-v7-teaser .menu {
    width: 500px;
    top: 80%;
    right: 80%;
  }

  /* v7 home */
  .fa7-pro-features-card-grid .card-1 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .fa7-pro-features-card-grid .card-2 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .fa7-pro-features-card-grid .card-3 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .fa7-pro-features-card-grid .card-4 {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .fa7-pro-features-card-grid img {
    max-width: 200%;
  }


  /* plans */
  .plans-summary-cards .card {
    --card-border-radius: 0;
  }

  .plans-summary-cards .card:first-child {
    --card-border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
  }

  .plans-summary-cards .card:last-child {
    --card-border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
    border-right: 0;
  }

  .plan-compare-table .compare-row dd {
    border-radius: 0;
  }

  .plan-compare-table .compare-row:first-child dd:first-child {
    border-radius: 0;
    border-top-left-radius: var(--border-radius-md);
  }

  .plan-compare-table .compare-row:first-child dd:last-child {
    border-radius: 0;
    border-top-right-radius: var(--border-radius-md);
  }

  .plan-compare-table .compare-row:last-child dd:first-child {
    border-radius: 0;
    border-bottom-left-radius: var(--border-radius-md);
  }

  .plan-compare-table .compare-row:last-child dd:last-child {
    border-radius: 0;
    border-bottom-right-radius: var(--border-radius-md);
  }

  /* purchase views + flows */
  .purchase-plan,
  .purchase-commission {
    order: 0;
  }

  .purchase-form {
    order: -1;
  }

  /* icons style menu */
  .icons-facets-group-styles-classic {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }

  /* style selection menu */
  .icons-style-menu-listing {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .icon-details-animation-button {
    flex: 1 0 30%;
  }

  /* categories */
  .all-categories-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  /* icon discovery */
  .icon-discovery .brands-icon-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .icon-pack-landing .pack-hero {
    --icon-pack-hero-min-height: 30vw;
  }

  .icon-pack-landing .pack-mockups {
    grid-template-columns: repeat(3, 1fr);
  }

  /* kits */
  .kit-listing-card > .header:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--card-border-radius);
    margin-bottom: calc(-1 * var(--card-vertical-padding) + var(--card-border-width));
    margin-right: 0;
  }

  .kit-setup .quick-start .tab-tag {
    position: relative;
    top: auto;
    left: auto;
  }

  .kit-summary-stats {
    gap: var(--spacing-2xl);
  }

  /* show 'name this kit' CTA on hover */
  .kit-listing-card:hover .name-this-kit {
    display: inline-block;
  }

  /* custom icon upload */
  .custom-icon-upload-card > .header:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--card-border-radius);
    margin-bottom: calc(-1 * var(--card-vertical-padding) + var(--card-border-width));
    margin-right: 0;
  }

  .custom-icon-upload-card .section {
    border-top: none;
    border-left: var(--card-section-border);
  }

  /* docs */
  .content__default .style-card .icons {
    border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
    grid-template-columns: repeat(3, minmax(1.5em, 1.5em));
  }

  .content__default p img {
    border-radius: var(--spacing-md);
  }

  .docs-search .algolia-autocomplete {
    display: inline-block !important;
  }

  /* outdated browser warning */
  .warning-internet-explorer {
    position: relative;
  }

  .warning-internet-explorer-decorative {
    position: absolute;
    top: -0.5em;
    left: 0.25em;
    width: var(--spacing-5xl);
    text-align: center;
  }

  .affiliates-sales-month {
    flex-grow: 0;
  }
}

/* custom > laptop */
@media (min-width: 1152px) {
  .modal .web-awesome-modal-content {
    width: 650px;
    --modal-max-width: 650px;
  }

  .wa-kickstarter-video {
    width: 650px;
    height: 365px;
  }

  /* bandana */
  .bandana-v7-teaser .pancakes {
    width: 330px;
    bottom: 38%;
    right: 80%;
  }

  .bandana-v7-teaser .coffee {
    width: 250px;
    top: 20%;
    left: 83%;
  }

  .bandana-v7-teaser .menu {
    width: 500px;
    top: 79%;
    right: 80%;
  }

  /* v7 home */
  .fa7-pro-features-card-grid .card-1 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .fa7-pro-features-card-grid .card-2 {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .fa7-pro-features-card-grid .card-3 {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .fa7-pro-features-card-grid .card-4 {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  /* plans */
  .plan-compare-table:first-child {
    position: relative;
    display: none;
  }

  .comparing-all .plan-compare-table:first-child {
    display: block;
  }

  .plan-compare-table .compare-row dd {
    border-radius: 0;
  }

  .plan-compare-table .compare-row:first-child dd:first-child {
    border-radius: 0;
    border-top-left-radius: var(--border-radius-md);
  }

  .plan-compare-table .compare-row:first-child dd:last-child {
    border-radius: 0;
    border-top-right-radius: var(--border-radius-md);
  }

  .plan-compare-table .compare-row:last-child dd:first-child {
    border-radius: 0;
    border-bottom-left-radius: var(--border-radius-md);
  }

  .plan-compare-table .compare-row:last-child dd:last-child {
    border-radius: 0;
    border-bottom-right-radius: var(--border-radius-md);
  }

  /* purchase views + flows */

  /* setting width to keep seat count + number edit transition smooth */
  .payment-choose-seats-count {
    min-width: var(--spacing-7xl);
  }

  /* style selection menu */
  .docs-card-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  /* icon facets */
  /* - .is-container-hidden is a workaround for https://github.com/mozilla/contain-facebook/issues/898 */
  .wrap-icons-facets-menu.is-container-hidden {
    margin-bottom: 0;

    /* overriding any hidden state icons facets menu from smaller viewports */
    opacity: 1;
    height: auto;
    visibility: visible;
  }

  .icons-facets-menu .icons-facets-group-editorial {
    order: 1;
  }

  .icons-facets-menu .wrap-ad {
    order: 2;
  }

  /* categories need to target this ais-class since it wraps the list */
  .icons-facets-menu .ais-RefinementList {
    order: 3;
  }

  /* icon details */
  .icon-detail-grid-preview {
    --order: 1;
  }

  .icon-detail-use {
    --order: 2;
  }

  .icon-detail-styling-tabs {
    --order: 3;
  }

  /* icon wizard */
  .icon-wizard-grid-preview {
    --order: 1;
  }

  .icon-wizard-use {
    --order: 2;
  }

  .icon-wizard-modifiers {
    --order: 3;
  }

  .icon-details-add-styling {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, calc(50% - var(--spacing-sm)));
    grid-row-gap: var(--spacing-sm);
    grid-column-gap: var(--spacing-xl);
  }

  .icon-details-styling-color {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .icon-details-styling-size {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .icon-details-styling-rotate {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .icon-details-styling-flip {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .icon-details-animation-button {
    flex: 1 0 18%;
  }

  .styling-flip.disabled,
  .styling-rotate.disabled {
    color: var(--fa-gravy);
    --label-color: var(--fa-gravy);

    .custom-radio-button-set {
      pointer-events: none;
    }
  }

  /* icons discovery */
  .icons-landing-stats {
    gap: var(--spacing-4xl);
  }

  .icons-landing .categories-listing-featured {
    grid-template-columns: repeat(4, 1fr);
  }

  /* icon packs */
  .icon-pack-landing .icon-pack-metadata {
    flex-grow: 0;
    gap: var(--spacing-3xl);
  }

  .icon-pack-landing .pack-hero {
    --icon-pack-hero-min-height: 35rem; /* ~684px */
  }

  .icon-pack-landing .pack-mockups {
    grid-template-columns: repeat(4, 1fr);
  }

  .icon-pack-landing .pack-mockup:first-of-type,
  .icon-pack-landing .pack-mockup:last-of-type {
    display: none;
  }

  .icon-pack-landing.icon-pack-landing-prerelease .pack-mockups {
    grid-template-columns: repeat(3, 1fr);
  }

  .icon-pack-landing.icon-pack-landing-prerelease .pack-mockup:first-of-type,
  .icon-pack-landing.icon-pack-landing-prerelease .pack-mockup:last-of-type {
    display: block;
  }

  /* .icon-pack-landing .pack-mockup-image {
    height: 400px;
  } */

  /* icons results + listing */
  .icons-results-summary-count {
    order: 1;
  }

  .icons-results-summary-facets {
    order: 2;
    width: auto;
  }

  .icons-results-summary-pages {
    order: 3;
  }

  /* modals */
  .modal .icon-detail-modal-content,
  .modal .uploaded-icon-detail-modal-content,
  .modal .pro-icon-modal-content,
  .modal .kit-subset-style-modal-content,
  .modal .frameworks-modal-content {
    --modal-max-width: 130ch; /* setting larger max-widths that match for icon detail + pro icon modals */
    width: 80vw;
  }

  /* upsell modal */
  .modal .upsell-modal-content {
    --modal-max-width: 112ch; /* ~1120px */
    width: 80vw;
  }

  .modal .upsell-modal-content .plan-card-summary {
    height: 100%; /* make modal-based plans have matching heights in laptop viewports */
  }

  /* categories */
  .all-categories-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  /* icon packs */
  .all-packs-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  /* icon collage/scene */
  .category-landing .category-header .scene {
    transform: translateY(-5em) translateX(9em);
  }

  .docs-page-nav.is-pinned {
    padding-top: var(--spacing-lg);
  }

  /* outdated browser warning */
  .warning-internet-explorer::after {
    display: block;
    content: '';
    clear: both;
  }

  /* Support */

  /* style selection menu */
  .support-card-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  /* kits */
  .kit-icon-styles-listing {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .kit-subset-style-modal-content .subset-status {
    width: auto;
  }

  .kit-subset-style-modal-content .subset-cancel {
    order: -1;
  }

  .icon-actions .icon-action-customize {
    flex-grow: 1;
  }

  /* kit wizard */
  .kit-wizard .kit-wizard-style-card-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

/* custom > desktop+ */
@media (min-width: 2000px) {
  /* icon packs */
  .icon-pack-landing .pack-mockups {
    grid-template-columns: repeat(6, 1fr);
  }

  .icon-pack-landing .pack-mockup:first-of-type {
    display: block;
  }

  .icon-pack-landing .pack-mockup:last-of-type {
    display: block;
  }
}

@media print {
  body {
    background: white !important;
    color: black !important;
    overflow: visible !important;
    height: 100%;
  }

  a:link,
  a:visited,
  a {
    background: transparent;
    color: #000;
    font-weight: bold;
    text-decoration: underline;
    text-align: left;
  }

  #impersonation-bar,
  #header,
  #footer {
    display: none;
  }

  .no-print {
    display: none;
  }
}
